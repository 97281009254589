import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../aggregations";
import { DeepPartial } from "../../../utils";
import { BaseSchemaDeal } from "../../base";

export const getBaseSchemaDealTemplate = (): DeepPartial<BaseSchemaDeal> => {
  const calculatedFieldTelpate = aggregationFieldTemplate();
  return {
    aggregations: {
      LoanProgramType: thirdPartyFieldTemplate({ defaultFocus: "override" }),
      EstimatedClosingDate: thirdPartyFieldTemplate({
        defaultFocus: "override"
      }),
      MonthlyPrincipalAndInterestPaymentAmount: calculatedFieldTelpate,
      TotalInitialFundedAmount: calculatedFieldTelpate,
      MonthlyInterestOnlyPaymentAmount: calculatedFieldTelpate,
      TotalCostBasisAmount: calculatedFieldTelpate,
      FullyDrawnInterestOnlyPayment: calculatedFieldTelpate,
      InitialMonthlyPayment: calculatedFieldTelpate
    }
  };
};
