import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import {
  PartyType,
  RolodexServiceProviderView,
  ServiceProviderEntityType,
  ServiceProviderStatusType
} from "@elphi/types";
import React, { ReactNode } from "react";
import { Card, CardOperation } from "../../../../../common-components/card";
import { EMPTY, NOT_AVAILABLE } from "../../../../../constants/common";
import { RolodexServiceProviderCard } from "../serviceProviderCard.types";
import {
  COLLAPSE_HEIGHT,
  SERVICE_PROVIDER_STATUS_BG_COLOR,
  SERVICE_PROVIDER_STATUS_COLOR_TEXT,
  entityTypeIconMap,
  entityTypeTextMap
} from "./utils.ts/card.utils";

type ServiceProviderCardProps = {
  data: RolodexServiceProviderCard;
  cardOperations: CardOperation[];
  entities: EntityIconProps["entities"];
  shouldHideEntitiesCount?: boolean;
};

export const ServiceProviderCard = (props: ServiceProviderCardProps) => {
  const { data, cardOperations, entities, shouldHideEntitiesCount } = props;

  const collapseHeight =
    entities.length === 0
      ? COLLAPSE_HEIGHT.No_Entity
      : entities.length === 1
      ? COLLAPSE_HEIGHT.One_Entity
      : COLLAPSE_HEIGHT.More_Then_One_Entity;
  const shouldAddBorder = data.status.find(
    (x) => x === ServiceProviderStatusType.Prohibited
  );
  return (
    <Card
      collapseHeight={collapseHeight}
      cardOperations={cardOperations}
      header={data.domainConfigurationName || NOT_AVAILABLE}
      defaultIsOpen={true}
      borderColor={shouldAddBorder ? "red.300" : undefined}
      minimizedView={
        <MinimizedInfo data={{ ...data }}>
          <EntityIcon
            entityType={data.entityType}
            entities={entities}
            shouldHideEntitiesCount={shouldHideEntitiesCount}
          />
        </MinimizedInfo>
      }
      maximizedView={
        <>
          <MaximizedProviderInfo
            title={"Company"}
            name={data.companyName}
            status={data.companyStatus}
          />
          <MaximizedProviderInfo
            title={"Branch"}
            name={data.branchName}
            status={data.branchStatus}
          />
          <MaximizedProviderInfo
            title={"Representative"}
            name={data.repName}
            status={data.repStatus}
          />
          {entities.map((e, i) => (
            <MaximizedEntityInfo
              key={i}
              title={`${entityTypeTextMap[data.entityType || EMPTY]} ${
                entities.length > 1 ? i + 1 : EMPTY
              }`}
              name={e.text}
            />
          ))}
        </>
      }
    />
  );
};

export const MaximizedProviderInfo = (props: {
  title: string;
  name: string;
  status?: ServiceProviderStatusType;
}) => {
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb={1}>
      <MaximizedInfo {...props} />
      <Badge
        fontSize={"12px"}
        fontWeight={"700"}
        color={SERVICE_PROVIDER_STATUS_COLOR_TEXT[props.status || EMPTY]}
        bgColor={SERVICE_PROVIDER_STATUS_BG_COLOR[props.status || EMPTY]}
      >
        {props.status || NOT_AVAILABLE}
      </Badge>
    </Flex>
  );
};

const MinimizedInfo = (
  props: {
    data: Pick<
      RolodexServiceProviderView,
      "companyName" | "branchName" | "repName" | "repSkipped"
    >;
  } & { children: React.ReactNode }
) => {
  const { data, children } = props;

  return (
    <Flex justifyContent={"space-between"} alignItems={"center"}>
      <Flex fontSize="14px" fontWeight={"600"} width={"80%"}>
        <TextEllipsis color={"gray.600"}>{data.companyName}</TextEllipsis>
        <Text mx={1}>|</Text>
        <TextEllipsis color={"gray.600"}>{data.branchName}</TextEllipsis>
        <Text mx={1}>|</Text>
        <TextEllipsis color={data.repSkipped ? "gray.400" : "gray.600"}>
          {data.repName}
        </TextEllipsis>
      </Flex>
      <Flex>{children}</Flex>
    </Flex>
  );
};

const MaximizedInfo = (props: { title: string; name: string }) => {
  return (
    <Box>
      <Text color={"gray.500"} fontSize="14px" fontWeight={"500"}>
        {props.title}
      </Text>
      <Text color={"black"} fontSize="14px" fontWeight={"600"}>
        {props.name}
      </Text>
    </Box>
  );
};

const MaximizedEntityInfo = (props: { title: string; name: string }) => {
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb={1}>
      <MaximizedInfo {...props} />
    </Flex>
  );
};

const PartyEntityIcon = ({
  entityTypeIcon,
  shouldHideEntitiesCount,
  entityValues
}: {
  entityTypeIcon: (type?: PartyType) => JSX.Element;
  shouldHideEntitiesCount?: boolean;
  entityValues: {
    id: string;
    text: string;
    type?: PartyType;
  }[];
}) => {
  const individualCount = entityValues.filter(
    (x) => x?.type === PartyType.Individual
  ).length;
  const entityCount = entityValues.filter(
    (x) => x?.type === PartyType.Entity
  ).length;

  return (
    <>
      {!shouldHideEntitiesCount ? (
        <>
          {entityTypeIcon(PartyType.Individual)}
          <EntityCount count={individualCount} />
          {entityTypeIcon(PartyType.Entity)}
          <EntityCount count={entityCount} />
        </>
      ) : (
        <>{entityTypeIcon(PartyType.Individual)}</>
      )}
    </>
  );
};
type EntityIconProps = {
  entityType: ServiceProviderEntityType;
  shouldHideEntitiesCount?: boolean;
  entities: {
    id: string;
    text: string;
    type?: PartyType;
  }[];
};
const EntityIcon = (props: EntityIconProps) => {
  const { entityType, entities } = props;
  const entityTypeIcon = entityTypeIconMap[entityType];
  const entityValues = Object.values(entities);

  return (
    <>
      {entityType === ServiceProviderEntityType.Party ? (
        <PartyEntityIcon
          entityTypeIcon={entityTypeIcon}
          shouldHideEntitiesCount={props.shouldHideEntitiesCount}
          entityValues={entityValues}
        />
      ) : (
        <>
          {entityTypeIcon?.()}
          <EntityCount
            shouldHideEntitiesCount={props.shouldHideEntitiesCount}
            count={entityValues.length}
          />
        </>
      )}
    </>
  );
};

const EntityCount = (props: {
  count: number;
  shouldHideEntitiesCount?: boolean;
}) => {
  if (props.shouldHideEntitiesCount) {
    return <></>;
  }

  return (
    <Text fontWeight={"bold"} mx={1} pt={"2px"}>
      {props.count}
    </Text>
  );
};

const TextEllipsis = (props: { color: string; children: ReactNode }) => {
  return (
    <Text
      color={props.color}
      maxWidth={"33%"}
      textOverflow={"ellipsis"}
      whiteSpace={"nowrap"}
      overflow={"hidden"}
    >
      {props.children}
    </Text>
  );
};
