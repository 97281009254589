import { merge } from "lodash";
import {
  ElphiEntityType,
  SystemSetup,
  getBaseSchemaDealTemplate,
  getBaseSchemaPropertyTemplate,
  getDealTemplate,
  getPropertyTemplateOmitCreate
} from "../../types";

type TemplateMap = Partial<
  Record<ElphiEntityType, Partial<Record<SystemSetup, () => object>>>
>;
const templatesMap: TemplateMap = {
  [ElphiEntityType.deal]: {
    [SystemSetup.Mofin]: getBaseSchemaDealTemplate,
    [SystemSetup.Elphi]: getBaseSchemaDealTemplate,
    [SystemSetup.Lima]: getDealTemplate
  },
  [ElphiEntityType.property]: {
    [SystemSetup.Elphi]: getBaseSchemaPropertyTemplate,
    [SystemSetup.Mofin]: getBaseSchemaPropertyTemplate,
    [SystemSetup.Lima]: getPropertyTemplateOmitCreate
  }
};

//TODO: remove "baseEntityTemplate" after each costumer has their own template
export const getEntityTemplate = <T extends object>(r: {
  entityType: ElphiEntityType;
  systemSetup: SystemSetup;
  baseEntityTemplate: T;
}) => {
  const { entityType, systemSetup, baseEntityTemplate } = r;
  const getSystemSetupTemplate = templatesMap?.[entityType]?.[systemSetup];
  if (!getSystemSetupTemplate) {
    const getElphiTempate = templatesMap?.[entityType]?.[SystemSetup.Elphi];
    if (!getElphiTempate) {
      throw new Error(
        `No template found for entity type: ${entityType} and system setup: ${systemSetup} and no default template for Elphi`
      );
    }
    const template = getElphiTempate();
    return merge({}, baseEntityTemplate, template);
  }
  const template = getSystemSetupTemplate();
  return merge({}, baseEntityTemplate, template);
};
