import { LimaDeal } from "@elphi/types/entities/lima/deal";
import {
  hideFixNFlip,
  hideFixNFlipOrEmpty,
  hideRental30,
  isEmptyLoanProgramType,
  isRental30Premier
} from "../../../../components/application/sections/sections.utils";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";
import { EMPTY } from "../../../../constants/common";
import {
  achElectedIndicator,
  aggregationLoanProductType,
  armFinalAdjustableRateTermMonthCount,
  armInitialFixedTermMonthCount,
  armRepriceLookbackType,
  armRepriceRoundingMethodType,
  armRepriceRoundingRatePercentType,
  capitalStructureType,
  cashFromBorrowerAmount,
  cashToBorrowerAmount,
  channel,
  closingAgentFirstName,
  closingAgentLastName,
  closingAnalystUserId,
  condoCertificationFeeAmount,
  contractorReviewFeeAmount,
  creditReportFeeAmount,
  defaultInterestRatePercent,
  downPaymentAmount,
  eoExpirationDate,
  estimatedClosingDate,
  exceptionApprovedBy,
  exceptionCommentary,
  exceptionType,
  feasibilityReviewFeeAmount,
  firstRateChangeDate,
  floodInsurancePremiumPaymentType,
  fundingAgentEmailAddress,
  gracePeriodDays,
  haircutAmount,
  indexSourceType,
  initialAdvancedFundAmount,
  interestAccrualMethodType,
  interestReserveDepositAmount,
  lastDayOfClosingMonth,
  lateFeePercent,
  lenderCounselFeeAmount,
  lenderIdentifier,
  lesserOfLotOrPurchase,
  loanAmortizationPeriodMonthCount,
  loanInterestOnlyPeriodMonthCount,
  loanMaturityDate,
  loanName,
  loanPackageInternationalExecutionIndicator,
  loanPackageNoteReceivedByLenderIndicator,
  loanPackageNoteTrackingNumber,
  loanPackageTrackingNumber,
  loanPaymentType,
  loanProgramType,
  loanPurposeType,
  loanTermPeriodMonthCount,
  mailAwayIndicator,
  netWireAmount,
  newYorkProcessingFeeAmount,
  notaryExpirationDate,
  notaryUserId,
  operationsDepartment,
  paymentDueDay,
  perDiemPrepaidInterestAmount,
  portfolioLoanIndicator,
  prepaidInterestAmount,
  prepaymentPenaltyExpirationDate,
  promissoryNoteComments,
  propertyInsurancePremiumPaymentType,
  purchaserWarrantyDeedAddedIndicator,
  rateLockEndDate,
  rateLockStartDate,
  requestedLoanAmount,
  scheduledFirstPaymentDate,
  sellerHUDAddedIndicator,
  settlementStatementComments,
  settlementStatementFileNumber,
  shippingCompanyType,
  submissionNotes,
  titleCommitmentAllSubjectPropertiesIncludedIndicator,
  titleCommitmentComments,
  titleCommitmentOwnership,
  titleCommitmentTitleEffectiveDate,
  titleCommitmentTitleExpirationDate,
  titleCommitmentTitleInternationalExecutionIndicator,
  titleCommitmentTitlePolicyAmount,
  titleCommitmentTitleVestingType,
  titleCompanyAddressLineText,
  titleCompanyAddressUnitIdentifier,
  titleCompanyCityName,
  titleCompanyClosingAgentContactPointEmail,
  titleCompanyClosingAgentContactPointTelephone,
  titleCompanyClosingAgentFirstName,
  titleCompanyClosingAgentLastName,
  titleCompanyName,
  titleCompanyPostalCode,
  titleCompanyStateCode,
  titleFormsComments,
  titleInsuranceUnderwriter,
  totalAdjustedMonthlyRentAmount,
  totalAsIsAppraisedValueAmount,
  totalAssignmentFeeAmount,
  totalBudgetAmount,
  totalCostAmount,
  totalFloodInsurancePremiumAmount,
  totalFloodInsurancePremiumOutstandingAmount,
  totalHOAFeesAmount,
  totalInitialInsuranceEscrowAmount,
  totalInitialTaxEscrowAmount,
  totalLTARVRatePercent,
  totalLTVRatePercent,
  totalLiquidAssetAmount,
  totalLoanFeesAndClosingCostAmount,
  totalNumberOfProperties,
  totalOutstandingLoanPayoffAmount,
  totalPropertyInsurancePremiumAmount,
  totalPropertyInsurancePremiumOutstandingAmount,
  totalPurchasePriceAmount,
  totalSubjectToAppraisedValueAmount,
  totalTaxAmount,
  totalValuationOrPurchasePriceAmount,
  uccExpirationDate,
  uccFiledIndicator,
  underwriterApprovalComments,
  underwriterApprovalStatus,
  warehouseBankStatusType,
  warehouseBankType,
  wireABARoutingNumber,
  wireAccountName,
  wireAccountNumber,
  wireBankName,
  wireReleaseDate
} from "../../base/inputs/deal.inputs";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import {
  achExemptionStatus,
  achRequiredIndicator,
  allongeAssignmentSentToWarehouseIndicator,
  allongeAssignmentTrackingNumber,
  amortizationType,
  applicationRequestedLoanAmount,
  appraisalNotes,
  armRepriceFrequencyType,
  arvAdjuster,
  associatedPropertiesDescription,
  blendedLTCAdjuster,
  brokerContactPointEmailValue,
  brokerContactPointTelephone,
  brokerFullName,
  brokerOriginationFeeAmount,
  brokerProcessingFeeAmount,
  brokerRepresentativeFirstName,
  brokerRepresentativeLastName,
  brokerWiringInstructionsIndicator,
  buildingEnvelopeExpandedIndicator,
  buildingPermitsExpectedDate,
  buildingPermitsIndicator,
  buyDownFeeAmount,
  buyUpFeeReductionAmount,
  campaignCode,
  campaignExpirationDate,
  campaignFeeIncentive,
  campaignLeverageIncentive,
  campaignRateIncentive,
  chargentCollectionAmount,
  chargentGatewayIdentifier,
  chargentTransactionIdentifier,
  constructionBudgetRemainingAmount,
  constructionBudgetVerifiedMatchIndicator,
  creditMemoApprovedDate,
  creditMemoDraftedDate,
  crmId,
  currentLotMarketValueAmount,
  debtServiceCoverageRatioPercent,
  deferredMaintenanceAmount,
  deferredOriginationFeeAmount,
  developmentStrategy,
  employeeLoanIndicator,
  eoNamedInsuredMatchIndicator,
  executionType,
  exitStrategy,
  externalApprovalDate,
  externalSubmissionDate,
  feeLLPAs,
  floodInsuranceAgencyFullName,
  floorPercent,
  fullyDrawnInterestOnlyPayment,
  fundingAndDisbursementApprovalIndicator,
  fundingShieldStatusType,
  generalContractorContactPointEmail,
  generalContractorContactPointTelephone,
  generalContractorFullName,
  generalContractorRepresentativeFirstName,
  generalContractorRepresentativeLastName,
  grossSpreadPercent,
  hoaContactEmail,
  hoaContactName,
  hoaContactPhone,
  initialLTVRatePercent,
  initialMonthlyInterestOnlyPaymentAmount,
  interestReserveEscrow,
  interestReserveEscrowOverrideAmount,
  interestReserveEscrowOverrideMonthCount,
  internalApprovalDate,
  internalL1CRefinanceIndicator,
  internalSubmissionDate,
  lOIAcceptedDate,
  lOIIssuedDate,
  lastPaymentDueDate,
  lenderFinanceApplicationFee,
  lenderFinanceAppraisalFee,
  lenderFinanceBorrowerFinalTermSheetApprovalStatusDate,
  lenderFinanceDocumentFee,
  lenderFinancePartnerFinalTermSheetApprovalStatusDate,
  lenderFinancePartnerFirstName,
  lenderFinancePartnerInitialTermSheetApprovalStatusDate,
  lenderFinancePartnerInitialTermSheetApprovalStatusIndicator,
  lenderFinancePartnerLastName,
  lenderFinanceProcessingFee,
  lenderPropertyInsuranceProviderIndicator,
  lenderTitleProviderIndicator,
  letterOfIntentSignedDate,
  lienDollarAmount,
  lifetimeCapPercent,
  limaOriginationFeeToBorrowerPercent,
  limaRateToBorrowerPercent,
  loadBearingWallsRemoveIntendedIndicator,
  loanAfterInterestOnlyPeriodMonthCount,
  loanAmountVerifiedMatchIndicator,
  loanPackageCheckAmount,
  loanPackageCheckNumber,
  loanPackageChecksReceivedIndicator,
  lotStatus,
  lotUtilitiesIndicator,
  lotZonedParcelIdIndicator,
  ltvEligibility,
  margin,
  monthlyHomeownersAssociationAmount,
  monthlyPaymentOfCapitalExpenditure,
  monthlyPrincipalInterestPaymentAmount,
  monthlyTaxInsurancePaymentAmount,
  nmlsIdentifier,
  noteRatePercent,
  occupancy,
  occupationIndicator,
  originationFeeAmount,
  personalGuarantorsCount,
  policySection,
  postClosingNotes,
  powerOfAttorneyFullName,
  prepaymentPenaltyType,
  pricingEngineMaxBlendedLoanToCostPercent,
  pricingEngineMaxLoanToAfterRepairValuePercent,
  pricingEngineMaxPurchaseLoanToCostPercent,
  pricingEngineNoteRateOverridePercent,
  pricingEngineOverrides,
  pricingEngineTotalBlendedLoanToCostOverridePercent,
  pricingEngineTotalLoanToAfterRepairValueOverridePercent,
  pricingEngineTotalPurchaseLoanToCostOverridePercent,
  pricingEngineWarnings,
  pricingInfo,
  processingFeeAmount,
  processingFeeOverride,
  programDirectorApprovalIndicator,
  programDirectorComments,
  projectAnticipatedCompletionTimeline,
  promissoryNoteRequestedIndicator,
  promissoryNoteShippedIndicator,
  promissoryNoteTrackingNumber,
  propertyInsuranceAgencyFullName,
  propertyLeasePurchaseOptionIndicator,
  propertyManagementComments,
  propertyManagementContactPointEmailValue,
  propertyManagementContactPointTelephoneValue,
  propertyManagementFullAddressName,
  propertyManagementFullName,
  propertyManagementRepresentativeFullName,
  propertyRentReadyConditionIndicator,
  propertyTypeChangingIndicator,
  propertyZoningChangesIndicator,
  purchaseLTCAdjuster,
  quoteId,
  rateLLPAs,
  recordedMortgageReceivedDate,
  recordedWarrantyDeedReceivedDate,
  recourseType,
  releasePercent,
  sellerRelationshipIndicator,
  servicingSetupFee,
  settlementStatementMatchLedgerIndicator,
  squareFootageAddedIndicator,
  subdividePropertyPartialReleasesIndicator,
  targetCloseDate,
  titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicator,
  titleCommitmentLeaseholdEndorsementIndicator,
  titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator,
  titlePolicyReceivedDate,
  totalAnnualCapitalExpenditure,
  totalBrokerFeeAmount,
  totalMonthlyPITIPaymentAmount,
  validationErrors,
  valuationFeesCollectedBySalesIndicator,
  verifiableConstructionCompletedAmount,
  wireInsuranceMatchDealIndicator
} from "../inputs/deal.inputs";
import { limaSpecs } from "../spec-files/limaFieldsSpecs";
import { LimaSections } from "../types";

const loanInformationSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Loan Information",
    inputs: [
      loanProgramType,
      loanPurposeType,
      loanName,
      portfolioLoanIndicator,
      estimatedClosingDate,
      channel,
      employeeLoanIndicator,
      chargentTransactionIdentifier,
      chargentGatewayIdentifier,
      chargentCollectionAmount,
      valuationFeesCollectedBySalesIndicator,
      appraisalNotes,
      crmId,
      operationsDepartment,
      lenderIdentifier
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const dealTotalsSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Deal Totals",
    inputs: [
      totalPurchasePriceAmount,
      addRuleToInput({
        input: totalBudgetAmount,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      totalAsIsAppraisedValueAmount,
      addRuleToInput({
        input: totalSubjectToAppraisedValueAmount,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      totalLTVRatePercent,
      addRuleToInput({
        input: totalLTARVRatePercent,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      ...addRulesToInputs({
        inputs: [totalTaxAmount, totalHOAFeesAmount],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      totalPropertyInsurancePremiumAmount,
      totalFloodInsurancePremiumAmount,
      addRuleToInput({
        input: totalCostAmount,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      requestedLoanAmount,
      addRuleToInput({
        input: initialLTVRatePercent,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),

      totalOutstandingLoanPayoffAmount,
      addRuleToInput({
        input: initialAdvancedFundAmount,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),

      totalLoanFeesAndClosingCostAmount,
      addRuleToInput({
        input: debtServiceCoverageRatioPercent,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),

      totalValuationOrPurchasePriceAmount,
      addRuleToInput({
        input: lesserOfLotOrPurchase,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),

      totalNumberOfProperties,
      totalAdjustedMonthlyRentAmount,
      ...addRulesToInputs({
        inputs: [
          totalAnnualCapitalExpenditure,
          monthlyPaymentOfCapitalExpenditure
        ],
        state: r.state,
        rules: [
          {
            field: "isHidden",
            predicate: () =>
              !isRental30Premier(r.state) && !isEmptyLoanProgramType(r.state)
          }
        ]
      }),

      cashFromBorrowerAmount,
      cashToBorrowerAmount,
      totalAssignmentFeeAmount
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const loanTermsSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Loan Terms",
    inputs: [
      addRuleToInput({
        input: aggregationLoanProductType,
        state: r.state,
        rules: [
          {
            field: "isHidden",
            predicate: () => !hideFixNFlipOrEmpty(r.state)
          }
        ]
      }),
      amortizationType,
      noteRatePercent,
      estimatedClosingDate,
      rateLockStartDate,
      rateLockEndDate,
      wireReleaseDate,
      scheduledFirstPaymentDate,
      paymentDueDay,
      lastPaymentDueDate,
      lastDayOfClosingMonth,
      loanMaturityDate,
      loanTermPeriodMonthCount,
      ...addRulesToInputs({
        inputs: [
          loanAmortizationPeriodMonthCount,
          loanInterestOnlyPeriodMonthCount,
          loanAfterInterestOnlyPeriodMonthCount,
          interestAccrualMethodType,
          loanPaymentType,
          totalMonthlyPITIPaymentAmount
        ],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      monthlyPrincipalInterestPaymentAmount,
      initialMonthlyInterestOnlyPaymentAmount,
      addRuleToInput({
        input: fullyDrawnInterestOnlyPayment,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      ...addRulesToInputs({
        inputs: [
          monthlyTaxInsurancePaymentAmount,
          monthlyHomeownersAssociationAmount
        ],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      addRuleToInput({
        input: executionType,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      ...addRulesToInputs({
        inputs: [prepaymentPenaltyType, prepaymentPenaltyExpirationDate],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      addRuleToInput({
        input: recourseType,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      campaignRateIncentive
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const loanTermsARMSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    isHidden: hideRental30(r.state),
    state: r.state,
    onChange: r.onChange,
    header: "Loan Terms - ARM",
    inputs: [
      firstRateChangeDate,
      indexSourceType,
      capitalStructureType,
      armRepriceRoundingRatePercentType,
      armRepriceRoundingMethodType,
      armRepriceLookbackType,
      armInitialFixedTermMonthCount,
      armFinalAdjustableRateTermMonthCount,
      armRepriceFrequencyType,
      margin,
      floorPercent,
      lifetimeCapPercent
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const closingLoanTermsARMSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) => loanTermsARMSection(r);

const loanTermsCustomSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Loan Terms - Custom",
    inputs: [
      uccFiledIndicator,
      uccExpirationDate,
      lateFeePercent,
      grossSpreadPercent,
      letterOfIntentSignedDate
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const propertyManagementQuestionnaireSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    isHidden: hideRental30(r.state),
    state: r.state,
    onChange: r.onChange,
    header: "Property Management Questionnaire",
    inputs: [
      propertyManagementFullName,
      propertyManagementRepresentativeFullName,
      propertyManagementFullAddressName,
      propertyManagementContactPointTelephoneValue,
      propertyManagementContactPointEmailValue,
      propertyManagementComments
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const titleCompanyClosingAttorneySection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Title Company",
    inputs: [
      titleInsuranceUnderwriter,
      titleCompanyName,
      titleCompanyClosingAgentFirstName,
      titleCompanyClosingAgentLastName,
      titleCompanyClosingAgentContactPointTelephone,
      titleCompanyClosingAgentContactPointEmail,
      titleCompanyAddressLineText,
      titleCompanyAddressUnitIdentifier,
      titleCompanyCityName,
      titleCompanyStateCode,
      titleCompanyPostalCode
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const thirdPartiesTitleCompanyClosingAttorneySection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) => titleCompanyClosingAttorneySection(r);

const generalContractorSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "General Contractor",
    inputs: [
      generalContractorFullName,
      generalContractorRepresentativeFirstName,
      generalContractorRepresentativeLastName,
      generalContractorContactPointTelephone,
      generalContractorContactPointEmail
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const brokerSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Broker",
    inputs: [
      brokerRepresentativeFirstName,
      brokerRepresentativeLastName,
      brokerFullName,
      brokerContactPointEmailValue,
      brokerContactPointTelephone,
      nmlsIdentifier,
      brokerWiringInstructionsIndicator
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const lenderFinanceSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Lender Finance",
    inputs: [
      lenderFinancePartnerFirstName,
      lenderFinancePartnerLastName,
      lenderFinancePartnerInitialTermSheetApprovalStatusIndicator,
      lenderFinancePartnerInitialTermSheetApprovalStatusDate,
      lenderFinancePartnerFinalTermSheetApprovalStatusDate,
      lenderFinanceBorrowerFinalTermSheetApprovalStatusDate
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const closingLoanTermsSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Loan Terms",
    inputs: [
      addRuleToInput({
        input: aggregationLoanProductType,
        state: r.state,
        rules: [
          {
            field: "isHidden",
            predicate: () => !hideFixNFlip(r.state)
          }
        ]
      }),
      amortizationType,
      noteRatePercent,
      estimatedClosingDate,
      rateLockStartDate,
      rateLockEndDate,
      wireReleaseDate,
      scheduledFirstPaymentDate,
      paymentDueDay,
      loanMaturityDate,
      loanTermPeriodMonthCount,
      ...addRulesToInputs({
        inputs: [
          loanAmortizationPeriodMonthCount,
          loanInterestOnlyPeriodMonthCount,
          loanAfterInterestOnlyPeriodMonthCount,
          interestAccrualMethodType,
          loanPaymentType,
          totalMonthlyPITIPaymentAmount
        ],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      monthlyPrincipalInterestPaymentAmount,
      initialMonthlyInterestOnlyPaymentAmount,
      addRuleToInput({
        input: fullyDrawnInterestOnlyPayment,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      ...addRulesToInputs({
        inputs: [
          monthlyTaxInsurancePaymentAmount,
          monthlyHomeownersAssociationAmount
        ],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      addRuleToInput({
        input: executionType,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      ...addRulesToInputs({
        inputs: [prepaymentPenaltyType, prepaymentPenaltyExpirationDate],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      lenderIdentifier,
      addRuleToInput({
        input: recourseType,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      campaignRateIncentive
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const closingLoanTermsCustomSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Loan Terms - Custom",
    inputs: [
      uccFiledIndicator,
      uccExpirationDate,
      defaultInterestRatePercent,
      lateFeePercent,
      releasePercent,
      grossSpreadPercent,
      gracePeriodDays,
      letterOfIntentSignedDate
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const ledgerSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Ledger",
    inputs: [
      totalOutstandingLoanPayoffAmount,
      totalPurchasePriceAmount,
      downPaymentAmount,
      originationFeeAmount,
      deferredOriginationFeeAmount,
      creditReportFeeAmount,
      processingFeeAmount,
      lenderCounselFeeAmount,
      buyDownFeeAmount,
      buyUpFeeReductionAmount,
      brokerOriginationFeeAmount,
      brokerProcessingFeeAmount,
      totalBrokerFeeAmount,
      ...addRulesToInputs({
        inputs: [contractorReviewFeeAmount, feasibilityReviewFeeAmount],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      condoCertificationFeeAmount,
      interestReserveEscrow,
      newYorkProcessingFeeAmount,
      chargentCollectionAmount,
      prepaidInterestAmount,
      perDiemPrepaidInterestAmount,
      servicingSetupFee,
      lenderFinanceProcessingFee,
      lenderFinanceApplicationFee,
      lenderFinanceDocumentFee,
      lenderFinanceAppraisalFee,
      campaignCode,
      campaignFeeIncentive,
      campaignExpirationDate
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const escrowsImpoundsSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Escrows/Impounds",
    inputs: [
      propertyInsurancePremiumPaymentType,
      totalPropertyInsurancePremiumOutstandingAmount,
      propertyInsuranceAgencyFullName,
      floodInsurancePremiumPaymentType,
      totalFloodInsurancePremiumOutstandingAmount,
      floodInsuranceAgencyFullName,
      ...addRulesToInputs({
        inputs: [
          totalInitialInsuranceEscrowAmount,
          totalInitialTaxEscrowAmount,
          interestReserveDepositAmount,
          deferredMaintenanceAmount
        ],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      addRuleToInput({
        input: totalBudgetAmount,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      })
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const titleCommitmentSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Title Commitment",
    inputs: [
      titleCommitmentTitleEffectiveDate,
      titleCommitmentTitleExpirationDate,
      titleCommitmentOwnership,
      titleCommitmentTitlePolicyAmount,
      addRuleToInput({
        input: titleCommitmentTitleVestingType,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      titleCommitmentAllSubjectPropertiesIncludedIndicator,
      titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicator,
      titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator,
      titleCommitmentTitleInternationalExecutionIndicator,
      addRuleToInput({
        input: titleCommitmentLeaseholdEndorsementIndicator,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      titleCommitmentComments
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const settlementStatementSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Settlement Statement",
    inputs: [
      totalLiquidAssetAmount,
      settlementStatementMatchLedgerIndicator,
      loanAmountVerifiedMatchIndicator,
      addRuleToInput({
        input: constructionBudgetVerifiedMatchIndicator,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      settlementStatementComments,
      cashFromBorrowerAmount,
      cashToBorrowerAmount
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const titleFormsSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Title Forms",
    inputs: [
      eoExpirationDate,
      eoNamedInsuredMatchIndicator,
      wireABARoutingNumber,
      wireAccountNumber,
      wireBankName,
      wireAccountName,
      wireInsuranceMatchDealIndicator,
      titleFormsComments
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const preFundingSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Pre-Funding",
    inputs: [
      netWireAmount,
      estimatedClosingDate,
      wireReleaseDate,
      warehouseBankType,
      warehouseBankStatusType,
      fundingShieldStatusType,
      haircutAmount,
      postClosingNotes,
      mailAwayIndicator
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const promissoryNoteWarehouseTrackingSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Promissory Note - Warehouse Tracking",
    inputs: [
      warehouseBankType,
      closingAgentFirstName,
      closingAgentLastName,
      promissoryNoteComments,
      shippingCompanyType,
      settlementStatementFileNumber,
      personalGuarantorsCount,
      powerOfAttorneyFullName,
      loanProgramType,
      loanPackageNoteTrackingNumber
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const promissoryNoteMFASection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Promissory Note - MFA",
    inputs: [
      promissoryNoteRequestedIndicator,
      promissoryNoteShippedIndicator,
      promissoryNoteTrackingNumber
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const userAssignmentSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "User Assignment",
    inputs: [notaryExpirationDate, closingAnalystUserId, notaryUserId],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const custodianCollateralTrackingSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Allonge and Assignment",
    inputs: [
      allongeAssignmentSentToWarehouseIndicator,
      allongeAssignmentTrackingNumber
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const electronicExecutedLoanPackageSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Electronic Executed Loan Package",
    inputs: [
      loanPackageTrackingNumber,
      loanPackageNoteTrackingNumber,
      fundingAndDisbursementApprovalIndicator,
      loanPackageInternationalExecutionIndicator,
      warehouseBankStatusType,
      achElectedIndicator,
      achRequiredIndicator,
      achExemptionStatus,
      sellerHUDAddedIndicator,
      purchaserWarrantyDeedAddedIndicator,
      fundingAgentEmailAddress
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const physicalExecutedLoanPackageSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Physical Executed Loan Package",
    inputs: [
      loanPackageTrackingNumber,
      loanPackageNoteReceivedByLenderIndicator,
      personalGuarantorsCount,
      loanPackageChecksReceivedIndicator,
      loanPackageCheckNumber,
      loanPackageCheckAmount,
      recordedMortgageReceivedDate,
      titlePolicyReceivedDate,
      recordedWarrantyDeedReceivedDate
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const underwritingDealApprovalSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Underwriting Deal Approval",
    inputs: [
      underwriterApprovalStatus,
      underwriterApprovalComments,
      programDirectorApprovalIndicator,
      programDirectorComments,
      submissionNotes
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const creditReviewCommitteeApprovalSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Credit Review Committee Approval",
    inputs: [
      creditMemoDraftedDate,
      creditMemoApprovedDate,
      internalSubmissionDate,
      internalApprovalDate,
      externalSubmissionDate,
      externalApprovalDate,
      lOIIssuedDate,
      lOIAcceptedDate
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const pricingEngineOverridesAndLLPAsSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Pricing Engine Overrides and LLPAs",
    inputs: [
      quoteId,
      pricingInfo,
      validationErrors,
      ltvEligibility,
      pricingEngineWarnings,
      pricingEngineOverrides,
      feeLLPAs,
      rateLLPAs,
      internalL1CRefinanceIndicator,
      limaRateToBorrowerPercent,
      limaOriginationFeeToBorrowerPercent,
      ...addRulesToInputs({
        inputs: [
          pricingEngineTotalLoanToAfterRepairValueOverridePercent,
          pricingEngineTotalBlendedLoanToCostOverridePercent,
          pricingEngineTotalPurchaseLoanToCostOverridePercent
        ],
        state: r.state,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlip(r.state)
          }
        ]
      }),
      pricingEngineNoteRateOverridePercent,
      ...addRulesToInputs({
        inputs: [
          pricingEngineMaxLoanToAfterRepairValuePercent,
          pricingEngineMaxBlendedLoanToCostPercent,
          pricingEngineMaxPurchaseLoanToCostPercent,
          blendedLTCAdjuster,
          purchaseLTCAdjuster,
          arvAdjuster
        ],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      interestReserveEscrowOverrideMonthCount,
      interestReserveEscrowOverrideAmount,
      processingFeeOverride,
      campaignCode,
      campaignRateIncentive,
      campaignFeeIncentive,
      campaignLeverageIncentive,
      campaignExpirationDate
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const exceptionSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Exceptions",
    inputs: [
      exceptionType,
      policySection,
      exceptionCommentary,
      exceptionApprovedBy
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const miscellaneousApplicationInformationSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Miscellaneous Application Information",
    inputs: [
      exitStrategy,
      targetCloseDate,
      applicationRequestedLoanAmount,
      projectAnticipatedCompletionTimeline,
      constructionBudgetRemainingAmount,
      verifiableConstructionCompletedAmount,
      lienDollarAmount,
      propertyTypeChangingIndicator,
      propertyZoningChangesIndicator,
      lenderPropertyInsuranceProviderIndicator,
      lenderTitleProviderIndicator,
      hoaContactName,
      hoaContactPhone,
      hoaContactEmail,
      occupancy,
      subdividePropertyPartialReleasesIndicator,
      propertyRentReadyConditionIndicator,
      propertyLeasePurchaseOptionIndicator,
      occupationIndicator,
      sellerRelationshipIndicator,
      associatedPropertiesDescription,
      developmentStrategy,
      lotStatus,
      currentLotMarketValueAmount,
      lotZonedParcelIdIndicator,
      buildingPermitsIndicator,
      buildingPermitsExpectedDate,
      lotUtilitiesIndicator,
      buildingEnvelopeExpandedIndicator,
      loadBearingWallsRemoveIntendedIndicator,
      squareFootageAddedIndicator
    ],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const dealDataCalendarSidebarSection = (r: {
  state: Partial<LimaDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: EMPTY,
    inputs: [estimatedClosingDate],
    fieldSpecs: limaSpecs.deal.entitySpecs
  });

const closingPreFundingSection = () => ({ inputs: [] });

export const dealSectionBuilders: LimaSections["deal"] = {
  loanInformationSection,
  dealTotalsSection,
  loanTermsSection,
  loanTermsARMSection,
  closingLoanTermsARMSection,
  loanTermsCustomSection,
  propertyManagementQuestionnaireSection,
  titleCompanyClosingAttorneySection,
  thirdPartiesTitleCompanyClosingAttorneySection,
  generalContractorSection,
  brokerSection,
  lenderFinanceSection,
  closingLoanTermsSection,
  closingLoanTermsCustomSection,
  ledgerSection,
  escrowsImpoundsSection,
  titleCommitmentSection,
  settlementStatementSection,
  titleFormsSection,
  preFundingSection,
  promissoryNoteWarehouseTrackingSection,
  promissoryNoteMFASection,
  userAssignmentSection,
  custodianCollateralTrackingSection,
  electronicExecutedLoanPackageSection,
  physicalExecutedLoanPackageSection,
  underwritingDealApprovalSection,
  creditReviewCommitteeApprovalSection,
  pricingEngineOverridesAndLLPAsSection,
  exceptionSection,
  miscellaneousApplicationInformationSection,
  dealDataCalendarSidebarSection,
  closingPreFundingSection
};
